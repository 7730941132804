<template>
  <div
    v-if="$api.dashboardData['isImpersonating']"
    class="alert alert-warning pt-1 pb-1 mb-2"
  >
    <div class="d-flex">
      <span class="d-inline-block my-auto">
        You are currently logged in as
        {{ $api.dashboardData['user']['email'] }}.
      </span>
      <button
        type="button"
        class="btn btn-link ms-auto"
        @click="$api.leaveImpersonation"
      >
        Return to your account
      </button>
    </div>
  </div>
</template>
