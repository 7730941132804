import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { api } from '@/plugins/api'

import DefaultLayout from '@/layouts/DefaultLayout'

const guestRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Login'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

const userRoutes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: '/users',
        name: 'Users',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/users/list',
        children: [
          {
            path: '/users/list',
            name: 'All Users',
            component: () => import('@/views/users/List.vue'),
          },
          {
            path: '/users/create',
            name: 'Create New User',
            component: () => import('@/views/users/Create.vue'),
          },
          {
            path: '/users/:user',
            name: 'Edit User',
            component: () => import('@/views/users/Edit.vue'),
          },
          {
            path: '/deleted-users',
            name: 'Deleted Users',
            component: () => import('@/views/users/DeletedList.vue'),
          },
        ],
      },
      {
        path: '/roles',
        name: 'Roles',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/roles/list',
        children: [
          {
            path: '/roles/list',
            name: 'All Roles',
            component: () => import('@/views/roles/List.vue'),
          },
          {
            path: '/roles/create',
            name: 'Create New Role',
            component: () => import('@/views/roles/Create.vue'),
          },
          {
            path: '/roles/:role',
            name: 'Edit Role',
            component: () => import('@/views/roles/Edit.vue'),
          },
        ],
      },
      {
        path: '/jobs',
        name: 'Jobs',
        component: () => import('@/views/jobs/List.vue'),
      },
      {
        path: '/deleted-jobs',
        name: 'Deleted Jobs',
        component: () => import('@/views/jobs/DeletedList.vue'),
      },
      {
        path: '/job-applications',
        name: 'Premium Job Applications',
        component: () => import('@/views/Applications.vue'),
        props: { isPremium: true },
      },
      {
        path: '/external-job-applications',
        name: 'External Job Applications',
        component: () => import('@/views/Applications.vue'),
        props: { isPremium: false },
      },
      {
        path: '/invoices',
        name: 'Invoices',
        component: () => import('@/views/Invoices.vue'),
      },
      {
        path: '/coupons',
        name: 'Coupons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/coupons/list',
        children: [
          {
            path: '/coupons/list',
            name: 'All Coupons',
            component: () => import('@/views/coupons/List.vue'),
          },
          {
            path: '/coupons/create',
            name: 'Create New Coupon',
            component: () => import('@/views/coupons/Create.vue'),
          },
          {
            path: '/coupons/:coupon',
            name: 'Edit Coupon',
            component: () => import('@/views/coupons/Edit.vue'),
          },
        ],
      },
      {
        path: '/salary-applications',
        name: 'Salary Applications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/salary-applications/list',
        children: [
          {
            path: '/salary-applications/list',
            name: 'All Salary Applications',
            component: () => import('@/views/salary-applications/List.vue'),
          },
          {
            path: '/salary-applications/:application',
            name: 'Edit Salary Application',
            component: () => import('@/views/salary-applications/Edit.vue'),
          },
        ],
      },
      {
        path: '/tracking/search',
        name: 'Searches',
        component: () => import('@/views/tracking/Search.vue'),
      },
      {
        path: '/tracking/registration',
        name: 'Registrations',
        component: () => import('@/views/tracking/Registration.vue'),
      },
      {
        path: '/companies',
        name: 'Company Profiles',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/companies/list',
        children: [
          {
            path: '/companies/list',
            name: 'All Companies',
            component: () => import('@/views/companies/List.vue'),
          },
        ],
      },
      {
        path: '/job-alerts',
        name: 'Job Alerts',
        component: () => import('@/views/JobAlerts.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'Clean Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    redirect: '/404',
    children: [
      {
        path: '/jobs/:job',
        name: 'View Job',
        component: () => import('@/views/jobs/View.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/404.vue'),
  },
]

const routes = api.isAuthenticated() ? userRoutes : guestRoutes

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
