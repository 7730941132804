<template>
  <CButton @click="submit" title="Delete" color="danger" class="me-1 pb-1">
    <slot>
      <CIcon icon="cilTrash" size="sm" class="text-white" />
    </slot>
  </CButton>
</template>

<script>
import Swal from 'sweetalert2'

export default {
  props: {
    endpoint: {
      type: Function,
      required: true,
    },
    endpointParams: {
      type: Array,
      default: () => [],
    },
    success: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    submit() {
      Swal.fire({
        title: 'Are you sure you want to delete this item?',
        showCancelButton: true,
        confirmButtonText: 'Confirm Delete',
        confirmButtonColor: '#e55353',
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#9da5b1',
        icon: 'warning',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.endpoint(...this.endpointParams).catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`)
          })
        },
        allowOutsideClick: () => !Swal.isLoading(),
        backdrop: true,
      }).then((result) => {
        this.success()
        if (result.value) {
          Swal.fire({
            title: 'Success!',
            text: 'Action has been completed successfully.',
            icon: 'success',
          })
        }
      })
    },
  },
}
</script>
