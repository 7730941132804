import intersection from 'lodash/intersection'
import { SUPERADMIN } from '@/constants'
import { api } from './plugins/api'

export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Users',
    icon: 'cil-people',
    isVisible: () =>
      intersection(api.dashboardPermissions, [
        'VIEW_USER_DE',
        'VIEW_USER_UK',
        'VIEW_USER_PH',
      ]).length > 0,
    items: [
      {
        component: 'CNavItem',
        name: 'All Users',
        to: '/users',
      },
      {
        component: 'CNavItem',
        name: 'Create New',
        to: '/users/create',
      },
      {
        component: 'CNavItem',
        name: 'Deleted Users',
        to: '/deleted-users',
      },
    ],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Roles',
  //   icon: 'cil-user-plus',
  //   isVisible: () => intersection(api.dashboardRoles, [SUPERADMIN]).length > 0,
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'All Roles',
  //       to: '/roles/list',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Create New',
  //       to: '/roles/create',
  //     },
  //   ],
  // },
  {
    component: 'CNavItem',
    name: 'Jobs',
    icon: 'cil-briefcase',
    isVisible: () =>
      intersection(api.dashboardPermissions, [
        'VIEW_JOB_DE',
        'VIEW_JOB_UK',
        'VIEW_JOB_PH',
      ]).length > 0,
    items: [
      {
        component: 'CNavItem',
        name: 'All Jobs',
        to: '/jobs',
      },
      {
        component: 'CNavItem',
        name: 'Deleted Jobs',
        to: '/deleted-jobs',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Applications',
    icon: 'cil-envelope-closed',
    isVisible: () =>
      intersection(api.dashboardPermissions, [
        'VIEW_JOB_DE',
        'VIEW_JOB_UK',
        'VIEW_JOB_PH',
      ]).length > 0,
    items: [
      {
        component: 'CNavItem',
        name: 'Premium Jobs',
        to: '/job-applications',
      },
      {
        component: 'CNavItem',
        name: 'External Jobs',
        to: '/external-job-applications',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Invoices',
    icon: 'cil-credit-card',
    isVisible: () =>
      intersection(api.dashboardPermissions, [
        'VIEW_INVOICE_DE',
        'VIEW_INVOICE_UK',
        'VIEW_INVOICE_PH',
      ]).length > 0,
    to: '/invoices',
  },
  {
    component: 'CNavItem',
    name: 'Coupons',
    icon: 'cil-tags',
    isVisible: () =>
      intersection(api.dashboardPermissions, [
        'SET_PAYMENT_DISCOUNTS_DE',
        'SET_PAYMENT_DISCOUNTS_UK',
        'SET_PAYMENT_DISCOUNTS_PH',
      ]).length > 0,
    items: [
      {
        component: 'CNavItem',
        name: 'All Coupons',
        to: '/coupons/list',
      },
      {
        component: 'CNavItem',
        name: 'Create New',
        to: '/coupons/create',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Salary Applications',
    icon: 'cil-envelope-letter',
    isVisible: () =>
      intersection(api.dashboardPermissions, ['VIEW_JOB_DE']).length > 0,
    to: '/salary-applications/list',
  },
  {
    component: 'CNavItem',
    name: 'Tracking',
    icon: 'cil-bar-chart',
    isVisible: () => intersection(api.dashboardRoles, [SUPERADMIN]).length > 0,
    items: [
      {
        component: 'CNavItem',
        name: 'Searches',
        to: '/tracking/search',
      },
      {
        component: 'CNavItem',
        name: 'Registrations',
        to: '/tracking/registration',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Company Profiles',
    icon: 'cil-building',
    items: [
      {
        component: 'CNavItem',
        name: 'All Companies',
        to: '/companies/list',
      },
    ],
    isVisible: () =>
      intersection(api.dashboardPermissions, [
        'VIEW_USER_DE',
        'VIEW_USER_UK',
        'VIEW_USER_PH',
      ]).length > 0,
  },
  {
    component: 'CNavItem',
    name: 'Job Alerts',
    icon: 'cil-paper-plane',
    isVisible: () =>
      intersection(api.dashboardPermissions, [
        'VIEW_USER_DE',
        'VIEW_USER_UK',
        'VIEW_USER_PH',
      ]).length > 0,
    to: '/job-alerts',
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Logs',
  //   icon: 'cil-list-rich',
  //   isVisible: () => intersection(api.dashboardRoles, [SUPERADMIN]).length > 0,
  //   href: process.env.VUE_APP_LOG_VIEWER,
  // },
]
