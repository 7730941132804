<template>
  <CButton
    @click="click"
    :href="href"
    :component="component"
    class="me-1"
    title="View"
  >
    <slot>
      <CIcon icon="cilMagnifyingGlass" size="sm" />
    </slot>
  </CButton>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
    },
    href: {
      type: String,
    },
  },
  computed: {
    component() {
      if (this.href) {
        return 'a'
      }
      return 'button'
    },
  },
  methods: {
    click() {
      if (this.to) {
        this.$router.push(this.to)
      }
      this.$emit('click')
    },
  },
}
</script>
