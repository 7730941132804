<template>
  <div class="container-fluid p-0">
    <div v-if="hasActiveFilters" class="mb-3">
      <small>Applied Filters: </small>
      <span
        v-for="(value, key) in activeFilters"
        :key="`filter-pill-${key}`"
        class="badge rounded-pill bg-info d-inline-flex align-items-center me-1 cursor-pointer"
      >
        {{ getSelectedFilterText(key) }}
        <span
          class="text-white ms-2 cursor-pointer"
          @click="$refs['filters'].removeFilter(key)"
        >
          <span class="visually-hidden">Remove filter option</span>
          <svg
            style="width: 0.5em; height: 0.5em"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              stroke-linecap="round"
              stroke-width="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </span>
      </span>
      <span
        class="badge rounded-pill bg-light text-dark cursor-pointer"
        @click="$refs['filters'].resetFilters()"
      >
        Clear
      </span>
    </div>

    <!-- Header -->
    <div class="d-md-flex justify-content-between mb-3">
      <div class="d-md-flex">
        <div v-if="isSearchVisible" class="mb-3 mb-md-0 input-group">
          <input
            v-model="search"
            type="text"
            class="form-control"
            placeholder="Search"
          />
        </div>
        <div :class="`${isSearchVisible ? 'ms-0 ms-md-2' : ''} mb-3 mb-md-0`">
          <Filters
            ref="filters"
            v-if="filters.length > 0"
            v-model="filterValues"
            :filters="filters"
          />
        </div>
      </div>
    </div>

    <!-- Table -->
    <CTable bordered hover responsive>
      <CTableHead>
        <CTableRow>
          <CTableHeaderCell
            v-for="(column, index) in columns"
            :key="index"
            scope="col"
          >
            <div class="d-flex align-items-center">
              <span>{{ column['text'] }}</span>
              <span
                v-if="typeof sortedBy[column['field']] !== 'undefined'"
                class="relative d-flex align-items-center"
                @click="sort(column['field'])"
              >
                <svg
                  v-if="sortedBy[column['field']] === 'asc'"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ms-1 cursor-pointer"
                  style="width: 1em; height: 1em"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <svg
                  v-else-if="sortedBy[column['field']] === 'desc'"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ms-1 cursor-pointer"
                  style="width: 1em; height: 1em"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  class="ms-1 cursor-pointer"
                  style="width: 1em; height: 1em"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
                  />
                </svg>
              </span>
            </div>
          </CTableHeaderCell>
        </CTableRow>
      </CTableHead>
      <CTableBody>
        <CTableRow v-for="row in rows" :key="row['id']">
          <CTableDataCell v-for="column in columns" :key="column['field']">
            <slot
              :name="column['field']"
              :data="{ value: getValue(column['field'], row), row }"
            >
              {{ getValue(column['field'], row) }}
            </slot>
          </CTableDataCell>
        </CTableRow>
      </CTableBody>
    </CTable>

    <!-- Pagination -->
    <div class="row" v-if="paginator['last_page'] > 1">
      <div class="col-12 col-md-6">
        <nav>
          <CPagination aria-label="Page navigation example">
            <CPaginationItem
              v-for="(link, index) in paginator['links']"
              :key="index"
              :disabled="!!link['disabled']"
              :active="link['page'] === paginator['current_page']"
              @click="gotoPage(link['page'])"
              :class="link['class']"
            >
              <span v-html="link['label']"></span>
            </CPaginationItem>
          </CPagination>
        </nav>
      </div>

      <div class="col-12 col-md-6 text-center text-md-end text-muted">
        <p>
          Showing
          <span class="fw-bold">
            {{ rows.length ? paginator['first_item'] : 0 }}
          </span>
          to
          <span class="fw-bold">
            {{ rows.length ? paginator['last_item'] : 0 }}
          </span>
          of <span class="fw-bold">{{ paginator['total'] }}</span>
          results
        </p>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12 text-muted">
        Showing
        <span class="fw-bold">{{ rows.length }}</span>
        results
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import debounce from 'lodash/debounce'
import startCase from 'lodash/startCase'
import Filters from '@/components/DataTable/Filters'
import { isNumeric } from '@/helpers'

export default {
  props: {
    ready: {
      type: Boolean,
      default: true,
    },
    endpoint: {
      type: Function,
      required: true,
    },
    endpointParams: {
      type: Object,
      default: () => ({}),
    },
    columns: {
      type: Array,
      required: true,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    searchFields: {
      type: Array,
      default: () => [],
    },
    singleColumnSorting: {
      type: Boolean,
      default: true,
    },
    defaultSortColumn: {
      type: String,
    },
    defaultSortDirection: {
      type: String,
      default: 'asc',
    },
  },
  components: { Filters },
  setup: function (props) {
    const filterValues = ref(
      props.filters.reduce((filters, filter) => {
        filters[filter['field']] = null
        return filters
      }, {}),
    )
    const activeFilters = computed(() => {
      const activeFilters = {}

      Object.keys(filterValues.value).forEach((key) => {
        if (filterValues.value[key]) {
          activeFilters[key] = filterValues.value[key]
        }
      })

      return activeFilters
    })
    const hasActiveFilters = computed(() => {
      return (
        Object.values(activeFilters.value).filter((value) => value !== null)
          .length > 0
      )
    })
    const isSearchVisible = computed(() => {
      return props.searchFields.length > 0
    })
    const page = ref(1)
    const paginator = ref({})
    const rows = ref([])
    const search = ref('')
    const sortedBy = ref(
      props.columns.reduce((columns, column) => {
        if (typeof column['sortable'] !== 'undefined' && column['sortable']) {
          columns[column['field']] =
            props.defaultSortColumn === column['field']
              ? props.defaultSortDirection
              : null
        }
        return columns
      }, {}),
    )

    const generatePaginator = (data) => {
      data['links'].shift()
      data['links'].pop()
      data['links'] = data['links'].map((link) => {
        link['page'] = isNumeric(link['label']) ? parseInt(link['label']) : null
        link['class'] = link['url'] ? 'cursor-pointer' : ''
        return link
      })
      data['links'].unshift({
        label: '&lsaquo;',
        page: data['current_page'] > 1 ? data['current_page'] - 1 : null,
        disabled: data['current_page'] === 1,
        class:
          data['current_page'] === 1 ? 'cursor-not-allowed' : 'cursor-pointer',
      })
      data['links'].push({
        label: '&rsaquo;',
        page:
          data['current_page'] < data['last_page']
            ? data['current_page'] + 1
            : null,
        disabled: data['current_page'] === data['last_page'],
        class:
          data['current_page'] === data['last_page']
            ? 'cursor-not-allowed'
            : 'cursor-pointer',
      })

      data['first_item'] =
        data['data'].length > 0
          ? (data['current_page'] - 1) * data['per_page'] + 1
          : null
      data['last_item'] =
        data['data'].length > 0
          ? data['first_item'] + data['data'].length - 1
          : null

      delete data['data']

      return data
    }
    const refreshData = async () => {
      if (!props.ready) {
        return
      }

      const filtersObject = props.filters.reduce((filters, filter) => {
        filters[filter['field']] = filter
        return filters
      }, {})

      // custom parameters
      const parameterFilters = props.filters
        .filter(
          (filter) =>
            typeof filter['asSearchable'] !== 'undefined' &&
            !filter['asSearchable'],
        )
        .map((filter) => filter['field'])

      // prepare search fields
      const searchFields = props.searchFields.map((field) => `${field}:like`)

      // prepare sortedBy
      const sortedByValues = Object.assign({}, sortedBy.value)
      if (
        props.defaultSortColumn &&
        typeof sortedByValues[props.defaultSortColumn] === 'undefined'
      ) {
        sortedByValues[props.defaultSortColumn] = props.defaultSortDirection
      }
      if (typeof sortedByValues['id'] === 'undefined') {
        sortedByValues['id'] = 'asc'
      }

      const endpointData = {
        search: `${search.value};${Object.keys(filterValues.value)
          .filter(
            (key) =>
              (typeof filtersObject[key]['asSearchable'] === 'undefined' ||
                filtersObject[key]['asSearchable']) &&
              filterValues.value[key] !== null,
          )
          .map((key) => {
            return `${key}:${
              typeof filterValues.value[key] !== 'boolean'
                ? filterValues.value[key]
                : filterValues.value[key]
                ? 1
                : 0
            }`
          })
          .join(';')}`.replace(/^;+|;+$/g, ''),
        page: page.value,
        searchFields: searchFields.join(';'),
        orderBy: Object.keys(sortedByValues)
          .filter((key) => sortedByValues[key] !== null)
          .map((key) => key.replaceAll('.', '|'))
          .join(';'),
        sortedBy: Object.keys(sortedByValues)
          .filter((key) => sortedByValues[key] !== null)
          .map((key) => sortedByValues[key])
          .join(';'),
      }
      Object.keys(props.endpointParams).forEach((key) => {
        endpointData[key] = props.endpointParams[key]
      })

      parameterFilters.forEach((filter) => {
        if (filterValues.value[filter]) {
          endpointData[filter] = filterValues.value[filter]
        }
      })

      const { data } = await props.endpoint(endpointData)
      rows.value = data['data']
      paginator.value = generatePaginator(data)
    }
    const delayedRefreshData = debounce(() => {
      refreshData()
    }, 150)

    watch(
      filterValues,
      () => {
        page.value = 1
        refreshData()
      },
      { deep: true },
    )
    watch(page, delayedRefreshData)
    watch(
      search,
      () => {
        page.value = 1
        delayedRefreshData()
      },
      { deep: true },
    )
    watch(
      sortedBy,
      () => {
        page.value = 1
        refreshData()
      },
      { deep: true },
    )

    if (props.filters.length === 0) {
      onMounted(refreshData)
    }

    return {
      activeFilters,
      filterValues,
      hasActiveFilters,
      isSearchVisible,
      page,
      paginator,
      refreshData: delayedRefreshData,
      rows,
      search,
      sortedBy,
    }
  },
  watch: {
    filters: {
      deep: true,
      handler(value) {
        this.filterValues = value.reduce((filters, filter) => {
          filters[filter['field']] = null
          return filters
        }, {})
      },
    },
  },
  methods: {
    gotoPage(page) {
      if (page) {
        this.page = page
      }
    },
    getValue(column, row) {
      if (typeof column === 'undefined') {
        return null
      }

      if (row[column]) {
        return row[column]
      }

      const dottedColumn = column.split('.')
      if (dottedColumn.length > 1) {
        let value = row[dottedColumn[0]]
        dottedColumn.shift()
        do {
          if (!value || typeof value !== 'object') {
            return null
          }
          value = value[dottedColumn[0]]
          dottedColumn.shift()
        } while (dottedColumn.length > 0)
        return value
      }

      return null
    },
    getSelectedFilterText(field) {
      let text = null

      this.filters
        .filter((filter) => filter['field'] === field)
        .forEach((filter) => {
          text = filter['text'] || startCase(filter['field'])
          if (filter['select']) {
            text += `: ${
              Array.isArray(filter['select'])
                ? this.filterValues[filter['field']]
                : filter['select'][this.filterValues[filter['field']]]
            }`
          }
        })

      return text
    },
    sort(field) {
      if (this.singleColumnSorting) {
        Object.keys(this.sortedBy).forEach((key) => {
          if (key !== field) {
            this.sortedBy[key] = null
          }
        })
      }

      if (this.sortedBy[field] === 'asc') {
        this.sortedBy[field] = 'desc'
      } else if (this.sortedBy[field] === 'desc') {
        this.sortedBy[field] = null
      } else {
        this.sortedBy[field] = 'asc'
      }
    },
  },
}
</script>
