<template>
  <div role="group">
    <span @click="edit" v-show="!isEditing">{{ text }}</span>
    <CFormInput
      ref="input"
      v-model="text"
      @blur="textChanged"
      @keyup.enter="textChanged"
      :invalid="!!error"
      v-show="isEditing"
    />
    <CFormFeedback v-if="error" invalid v-text="error"></CFormFeedback>
  </div>
</template>

<script>
import { ref, toRefs, watch } from 'vue'

export default {
  props: {
    modelValue: {
      type: String,
      default: undefined,
      require: false,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    data: {
      default: () => [],
    },
  },
  setup(props) {
    const { modelValue } = toRefs(props)

    const error = ref('')
    const isEditing = ref(false)
    const savedText = ref(props.modelValue)
    const text = ref(props.modelValue)

    watch(modelValue, (value) => {
      text.value = value
      savedText.value = value
      error.value = ''
    })

    return {
      error,
      isEditing,
      savedText,
      text,
    }
  },
  methods: {
    edit() {
      this.isEditing = true
      setTimeout(() => {
        this.$refs['input'].$el.focus()
      }, 50)
    },
    textChanged() {
      if (!this.isEditing) {
        return
      }
      if (this.text === this.savedText) {
        this.isEditing = false
        return
      }
      if (this.text.length === 0) {
        this.text = this.savedText
        this.isEditing = false
        return
      }

      const success = () => {
        this.isEditing = false
        this.error = ''
        this.savedText = this.text
      }
      const fail = (error) => {
        this.error = error
      }

      this.callback(this.text, success, fail, this.data)
    },
  },
}
</script>
