import axios from 'axios'
import concat from 'lodash/concat'
import { showErrorAlert } from '@/helpers'

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT.trim('/')

const urlBuilder = (url) => {
  url = new URL(`${API_ENDPOINT}/${url}`)
  url.searchParams.append('lang', 'en')
  return url.toString()
}

class Api {
  dashboardData = undefined

  get dashboardRoles() {
    if (typeof this.dashboardData === 'undefined') {
      return []
    }

    return concat(
      ...this.dashboardData['user']['roles'].map((role) => role['name']),
    )
  }

  get dashboardPermissions() {
    if (typeof this.dashboardData === 'undefined') {
      return []
    }

    return concat(
      ...this.dashboardData['user']['roles'].map((role) =>
        role['permissions'].map((permission) => permission['name']),
      ),
    )
  }

  async setup() {
    axios.defaults.withCredentials = true
    await axios.get(urlBuilder('sanctum/csrf-cookie')).then(async () => {
      await axios
        .get(urlBuilder('api/admin/dashboard'))
        .then(({ data }) => {
          this.dashboardData = data
        })
        .catch(({ response }) => {
          if (response.status !== 401) {
            showErrorAlert()
          }
        })
    })
  }

  isAuthenticated() {
    return !!this.dashboardData
  }

  impersonate(id) {
    location.href = urlBuilder(`impersonate/take/${id}`)
  }

  leaveImpersonation() {
    location.href = urlBuilder('impersonate/leave')
  }

  login(data) {
    return axios.post(urlBuilder('login'), data)
  }

  logout() {
    return axios.post(urlBuilder('logout'))
  }

  /**
   * Users
   */

  users(params = {}) {
    return axios.get(urlBuilder('api/users'), { params })
  }
  'deleted-users' = (params = {}) => {
    return axios.get(urlBuilder('api/users?deleted'), { params })
  }
  'users.store' = (data) => {
    return axios.post(urlBuilder(`api/users`), data)
  }
  'users.show' = (userId) => {
    return axios.get(urlBuilder(`api/users/${userId}`))
  }
  'users.send-verification' = (userId) => {
    return axios.post(urlBuilder(`api/users/${userId}/send-verification`))
  }
  'users.update' = (userId, data) => {
    return axios.patch(urlBuilder(`api/users/${userId}`), data)
  }
  'users.delete' = (userId) => {
    return axios.delete(urlBuilder(`api/users/${userId}`))
  }
  'users.restore' = (userId) => {
    return axios.post(urlBuilder(`api/users/${userId}/restore`))
  }
  'users.delete-permanently' = (userId) => {
    return axios.delete(urlBuilder(`api/users/${userId}?permanently`))
  }

  /**
   * Roles and Permissions
   */

  roles(params = {}) {
    return axios.get(urlBuilder('api/roles'), { params })
  }
  permissions(params = {}) {
    return axios.get(urlBuilder('api/permissions'), { params })
  }
  'roles.store' = (data) => {
    return axios.post(urlBuilder(`api/roles`), data)
  }
  'roles.show' = (roleId) => {
    return axios.get(urlBuilder(`api/roles/${roleId}`))
  }
  'roles.update' = (roleId, data) => {
    return axios.patch(urlBuilder(`api/roles/${roleId}`), data)
  }
  'roles.delete' = (roleId) => {
    return axios.delete(urlBuilder(`api/roles/${roleId}`))
  }

  /**
   * Jobs
   */

  jobs(params = {}) {
    return axios.get(urlBuilder('api/jobs'), { params })
  }
  'deleted-jobs' = (params = {}) => {
    return axios.get(urlBuilder('api/jobs?deleted'), { params })
  }
  'jobs.show' = (jobId) => {
    return axios.get(urlBuilder(`api/jobs/${jobId}`))
  }
  'jobs.approve' = (jobId) => {
    return axios.post(urlBuilder(`api/jobs/${jobId}/approve`))
  }
  'jobs.feature' = (jobId) => {
    return axios.post(urlBuilder(`api/jobs/${jobId}/feature`))
  }
  'jobs.unfeature' = (jobId) => {
    return axios.post(urlBuilder(`api/jobs/${jobId}/unfeature`))
  }
  'jobs.list' = (jobId) => {
    return axios.post(urlBuilder(`api/jobs/${jobId}/list`))
  }
  'jobs.unlist' = (jobId) => {
    return axios.post(urlBuilder(`api/jobs/${jobId}/unlist`))
  }
  'jobs.unlist' = (jobId) => {
    return axios.post(urlBuilder(`api/jobs/${jobId}/unlist`))
  }
  'jobs.set-new-invoice' = (jobId) => {
    return axios.post(urlBuilder(`api/jobs/${jobId}/invoice`))
  }
  'jobs.expire' = (jobId, params = {}) => {
    return axios.post(urlBuilder(`api/jobs/${jobId}/expire`), params)
  }
  'jobs.delete' = (jobId) => {
    return axios.delete(urlBuilder(`api/jobs/${jobId}`))
  }
  'jobs.restore' = (jobId) => {
    return axios.post(urlBuilder(`api/jobs/${jobId}/restore`))
  }
  'jobs.delete-permanently' = (jobId) => {
    return axios.delete(urlBuilder(`api/jobs/${jobId}?permanently`))
  }

  /**
   * Applications
   */
  applications = (params = {}) => {
    return axios.get(urlBuilder('api/applications'), { params })
  }
  'applications.send' = (applicationId) => {
    return axios.post(urlBuilder(`api/applications/${applicationId}/send`))
  }
  'applications.trash' = (applicationId) => {
    return axios.post(urlBuilder(`api/applications/${applicationId}/trash`))
  }
  'applications.update' = (applicationId, data) => {
    return axios.patch(urlBuilder(`api/applications/${applicationId}`), data)
  }

  /**
   * Invoices
   */
  invoices(params = {}) {
    return axios.get(urlBuilder('api/invoices'), { params })
  }
  'urls.invoices.download' = (invoiceId) => {
    return urlBuilder(`api/invoices/${invoiceId}/pdf`)
  }
  'invoices.resend' = (invoiceId) => {
    return axios.post(urlBuilder(`api/invoices/${invoiceId}/resend`))
  }
  'invoices.set-as-paid' = (invoiceId) => {
    return axios.post(urlBuilder(`api/invoices/${invoiceId}/set-as-paid`))
  }
  'invoices.delete' = (invoiceId) => {
    return axios.delete(urlBuilder(`api/invoices/${invoiceId}`))
  }

  /**
   * Coupons
   */
  coupons(params = {}) {
    return axios.get(urlBuilder('api/coupons'), { params })
  }
  'coupons.suggest-code' = (data) => {
    return axios.post(urlBuilder(`api/coupons/suggest-code`), data)
  }
  'coupons.store' = (data) => {
    return axios.post(urlBuilder(`api/coupons`), data)
  }
  'coupons.show' = (couponId) => {
    return axios.get(urlBuilder(`api/coupons/${couponId}`))
  }
  'coupons.update' = (couponId, data) => {
    return axios.patch(urlBuilder(`api/coupons/${couponId}`), data)
  }
  'coupons.delete' = (couponId) => {
    return axios.delete(urlBuilder(`api/coupons/${couponId}`))
  }

  /**
   * Salary Applicaitons
   */
  'salary-applications' = (params = {}) => {
    return axios.get(urlBuilder('api/salary-applications'), { params })
  }
  'salary-applications.show' = (application) => {
    return axios.get(urlBuilder(`api/salary-applications/${application}`))
  }
  'salary-applications.update' = (application, data) => {
    return axios.patch(
      urlBuilder(`api/salary-applications/${application}`),
      data,
    )
  }
  'salary-applications.trash' = (applicationId) => {
    return axios.post(
      urlBuilder(`api/salary-applications/${applicationId}/trash`),
    )
  }

  /**
   * Tracking
   */
  'tracking.search' = (params = {}) => {
    return axios.get(urlBuilder('api/tracking/search'), { params })
  }
  'tracking.registration' = (params = {}) => {
    return axios.get(urlBuilder('api/tracking/registration'), { params })
  }

  /**
   * Companies
   */

  companies(params = {}) {
    return axios.get(urlBuilder('api/companies'), { params })
  }
  'companies.show' = (companyId) => {
    return axios.get(urlBuilder(`api/companies/${companyId}`))
  }
  'companies.delete' = (companyId) => {
    return axios.delete(urlBuilder(`api/companies/${companyId}`))
  }

  /**
   * Matchmaking
   */
  matchmaking(params = {}) {
    return axios.get(urlBuilder('api/matchmaking'), { params })
  }
}

export const api = new Api()

export default {
  install(app) {
    app.config.globalProperties.$api = api
  },
}
