<template>
  <div
    @keydown.esc.stop="isOpen = false"
    v-click-outside="close"
    class="btn-group d-block d-md-inline"
  >
    <button
      @click="isOpen = !isOpen"
      type="button"
      class="btn dropdown-toggle d-block w-100 d-md-inline"
    >
      <CIcon icon="cilFilter" size="sm"></CIcon>
      Filters
      <span class="caret"></span>
    </button>
    <ul class="dropdown-menu w-100" :class="{ show: isOpen }" role="menu">
      <li>
        <div v-for="filter in filters" :key="filter['field']" class="p-2">
          <!-- Select -->
          <template v-if="typeof filter['select'] !== 'undefined'">
            <label :for="`filter-filter[${filter['field']}]`" class="mb-2">
              {{ filter['text'] || startCase(filter['field']) }}
            </label>

            <select
              onclick="event.stopPropagation();"
              v-model="filterValues[filter['field']]"
              :id="`filter-filter[${filter['field']}]`"
              class="form-select"
            >
              <option :value="null">--- SELECT ---</option>
              <option
                v-for="(text, key) in formatSelect(filter['select'])"
                :key="key"
                :value="key"
              >
                {{ text }}
              </option>
            </select>
          </template>

          <!-- Checkbox -->
          <template v-else>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="filterValues[filter['field']]"
                :id="`filter-filter[${filter['field']}]`"
              />
              <label
                class="form-check-label"
                :for="`filter-filter[${filter['field']}]`"
              >
                {{ filter['text'] || startCase(filter['field']) }}
              </label>
            </div>
          </template>
        </div>

        <div class="dropdown-divider"></div>
        <button
          @click.prevent="resetFilters"
          @click="isOpen = false"
          class="dropdown-item text-center"
        >
          Clear
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, ref, toRaw } from 'vue'
import debounce from 'lodash/debounce'
import startCase from 'lodash/startCase'

export default {
  props: {
    filters: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const getFilterDefaultValue = (filter, defaultValue = null) => {
      if (defaultValue !== null) {
        return defaultValue
      }

      if (typeof filter['select'] !== 'undefined') {
        return null
      }

      return false
    }
    const filterValues = ref(
      props.filters.reduce((filtersObj, filter) => {
        filtersObj[filter['field']] = getFilterDefaultValue(
          filter,
          props.modelValue[filter['field']],
        )
        return filtersObj
      }, {}),
    )
    const isOpen = ref(false)

    const filterDictionary = computed(() => {
      return props.filters.reduce((filtersObj, filter) => {
        filtersObj[filter['field']] = filter
        return filtersObj
      }, {})
    })

    return {
      filterDictionary,
      filterValues,
      getFilterDefaultValue,
      isOpen,
    }
  },
  watch: {
    filterValues: {
      deep: true,
      handler() {
        this.emitValues()
      },
    },
  },
  mounted() {
    this.emitValues()
  },
  methods: {
    startCase,
    close() {
      this.isOpen = false
    },
    resetFilters() {
      Object.keys(toRaw(this.filterValues)).forEach((key) => {
        this.filterValues[key] = this.getFilterDefaultValue(
          this.filterDictionary[key],
        )
      })
    },
    removeFilter(field) {
      this.filterValues[field] = this.getFilterDefaultValue(
        this.filterDictionary[field],
      )
    },
    emitValues() {
      this.emitValuesHandler(this)
    },
    emitValuesHandler: debounce((vm) => {
      vm.$emit('update:modelValue', vm.filterValues)
    }, 50),
    formatSelect(select) {
      if (Array.isArray(select)) {
        return select.reduce((select, status) => {
          select[status] = status
          return select
        }, {})
      }

      return select
    },
  },
}
</script>
