<template>
  <CButton @click="submit" class="me-1">
    <slot></slot>
  </CButton>
</template>

<script>
import Swal from 'sweetalert2'

export default {
  props: {
    endpoint: {
      type: Function,
      required: true,
    },
    endpointParams: {
      type: Array,
      default: () => [],
    },
    swalIcon: {
      type: String,
      default: 'warning',
    },
    success: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    submit() {
      Swal.fire({
        title: 'Are you sure you want to do this?',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        confirmButtonColor: '#321fdb',
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#9da5b1',
        icon: this.swalIcon,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.endpoint(...this.endpointParams).catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`)
          })
        },
        allowOutsideClick: () => !Swal.isLoading(),
        backdrop: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Success!',
            text: 'Action has been completed successfully.',
            icon: 'success',
          }).then(() => {
            this.success()
          })
        }
      })
    },
  },
}
</script>
