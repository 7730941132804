import { createApp } from 'vue'
import NProgress from 'nprogress'
import axios from 'axios'
import App from './App.vue'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import LoadScript from 'vue-plugin-load-script'
import ConfirmButton from '@/components/utils/ConfirmButton'
import DataTable from '@/components/DataTable'
import EditableText from '@/components/EditableText'
import DeleteButton from '@/components/utils/DeleteButton'
import ViewButton from '@/components/utils/ViewButton'

import Api, { api } from '@/plugins/api'

axios.interceptors.request.use(
  (config) => {
    NProgress.start()
    return config
  },
  (error) => {
    NProgress.done()
    return Promise.reject(error)
  },
)
axios.interceptors.response.use(
  (config) => {
    NProgress.done()
    return config
  },
  (error) => {
    NProgress.done()
    return Promise.reject(error)
  },
)

const app = createApp(App)
api.setup().then(() => {
  if (!api.isAuthenticated()) {
    location.href = process.env.VUE_APP_LOGIN
    return
  }

  app.use(store)
  app.use(require('./router').default)
  app.use(CoreuiVue)
  app.use(Api)
  app.use(LoadScript)
  app.provide('icons', icons)
  app.component('CIcon', CIcon)
  app.component('ConfirmButton', ConfirmButton)
  app.component('DataTable', DataTable)
  app.component('EditableText', EditableText)
  app.component('DeleteButton', DeleteButton)
  app.component('ViewButton', ViewButton)

  app.directive('click-outside', {
    beforeMount: function (el, binding) {
      // Define ourClickEventHandler
      const ourClickEventHandler = (event) => {
        if (!el.contains(event.target) && el !== event.target) {
          // as we are attaching an click event listern to the document (below)
          // ensure the events target is outside the element or a child of it
          binding.value(event) // before binding it
        }
      }
      // attached the handler to the element so we can remove it later easily
      el.__vueClickEventHandler__ = ourClickEventHandler

      // attaching ourClickEventHandler to a listener on the document here
      document.addEventListener('click', ourClickEventHandler)
    },
    unmounted: function (el) {
      // Remove Event Listener
      document.removeEventListener('click', el.__vueClickEventHandler__)
    },
  })

  app.mount('#app')
})
